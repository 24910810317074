export const iconSettings = {
	mdi: {
		size: 24,
		viewbox: "0 0 24 24",
	},
	"simple-icons": {
		size: 24,
		viewbox: "0 0 24 24",
	},
	default: {
		size: 0,
		viewbox: "0 0 0 0",
	},
};
